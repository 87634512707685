
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
export default {
  name: 'LogosCarousel',
  props: ['id','title','items','logoPosition','minSlidesPerView','slidesPerView','pretitle', 'showDescription'],
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = new Swiper(`#${this.id}-carousel`, {
      slidesPerView: this.minSlidesPerView || 1,
      spaceBetween: 20,
      speed: 500,
      pagination: {
        el: `#${this.id}-carousel-pagination`,
        clickable: true,
      },
      watchOverflow: true,
      breakpoints: {
        1280: {
          slidesPerView: this.slidesPerView || 4,
        },
        1024: {
          slidesPerView: this.slidesPerView || 4,
        },
        768: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: this.minSlidesPerView || 1,
        },
      },
    })
  }
}
